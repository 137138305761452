import { render, staticRenderFns } from "./TopStreamerVideo.vue?vue&type=template&id=96928660&scoped=true&"
import script from "./TopStreamerVideo.vue?vue&type=script&lang=js&"
export * from "./TopStreamerVideo.vue?vue&type=script&lang=js&"
import style0 from "./TopStreamerVideo.vue?vue&type=style&index=0&id=96928660&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96928660",
  null
  
)

export default component.exports