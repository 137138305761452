<template>
  <div>
    <FrontVideo
      :videos="latestVideos"
      :host="host"
      v-if="latestVideos.length"
    />
    <OthersStreamers
      :streamers="streamers"
      :host="host"
      id="0"
      title="Streamer Spotlight"
      v-if="streamers.length"
    />
    <TopCategoryVideo
      :categoryVideos="topCategoryVideos"
      :host="host"
      title="Top Games"
      v-if="topCategoryVideos.length"
    />
    <TopStreamerVideo
      :streamerVideos="topStreamerVideos"
      :host="host"
      title="Top Streams"
      v-if="topStreamerVideos.length"
    />
  </div>
</template>

<script>
import axios from "axios";
import FrontVideo from "@/components/FrontVideo.vue";
import OthersStreamers from "@/components/OthersStreamers.vue";
import TopCategoryVideo from "@/components/TopCategoryVideo.vue";
import TopStreamerVideo from "@/components/TopStreamerVideo.vue";

export default {
  name: "Streamer",
  components: {
    FrontVideo,
    OthersStreamers,
    TopCategoryVideo,
    TopStreamerVideo
  },
  data: () => {
    return {
      host: null,
      loading: true,
      error: false,
      message: null,
      latestVideos: [],
      streamers: [],
      topCategoryVideos: [],
      topStreamerVideos: []
    };
  },
  async created() {
    this.host = this.$root.url();
    await this.homeVideo();
  },
  methods: {
    async homeVideo() {
      try {
        const path = "video";
        let url = `${this.host}/${path}`;
        let response = await axios.get(url);
        if (response.data.success) {
          this.latestVideos = response.data.latestVideo;
          this.streamers = response.data.streamer;
          this.topCategoryVideos = response.data.topVideo;
          this.topStreamerVideos = response.data.topStream;
        } else {
          let errorObject = {
            status: true,
            message: "Something wrong, please try again later"
          };
          this.errorMessage(errorObject);
        }
        this.loading = false;
      } catch (e) {
        let errorObject = {
          status: true,
          message: "Something wrong, please try again later"
        };
        this.errorMessage(errorObject);
      }
    },
    errorMessage(error) {
      this.error = error.status;
      this.message = error.message;
    }
  }
};
</script>

<style scoped></style>
