<template>
  <div class="live-video-section">
    <ul>
      <li v-for="video in videos" :key="video.id">
        <router-link :to="{ name: 'VideoPlay', params: { videoId: video.id } }">
          <img :src="video.thumbnail_path" />
          <div class="video-placeholder">
            <div>
              <img src="../assets/eye-icons.svg" alt="" /> {{ video.view }}
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FrontVideo",
  props: ["videos", "host"]
};
</script>

<style scoped></style>
