<template>
  <div>
    <div class="vd-pg-tl">
      <ul>
        <li class="vd-01">{{ title }}</li>
        <li class="vd-02">
          <button type="button">See All</button>
        </li>
      </ul>
    </div>

    <div class="top-games-section">
      <ul>
        <li v-for="video in categoryVideos" :key="video.id">
          <router-link
            :to="{ name: 'VideoPlay', params: { videoId: video.id } }"
          >
            <img :src="video.thumbnail_path" />
            <div class="top-games-placeholder">
              <h3>{{ shortDescription(video.title) }}</h3>
              <div><img src="../assets/eye-icons.svg" /> {{ video.view }}</div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopCategoryVideo",
  props: ["categoryVideos", "host", "title"],
  methods: {
    shortDescription: function(str) {
      //alert(str);
      let result = str;
      let resultArray = result.split(" ");
      if (resultArray.length > 4) {
        resultArray = resultArray.slice(0, 4);
        result = resultArray.join(" ") + "…";
      }
      return result;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
