<template>
  <div>
    <div class="vd-pg-tl strm-01">
      <ul>
        <li class="vd-01">{{ title }}</li>
        <li class="vd-02"><button type="button">See All</button></li>
      </ul>
    </div>
    <div class="streamer-spotlight-section">
      <ul>
        <li v-for="streamer in videos" :key="streamer.id">
          <router-link :to="{ name: 'Streamer', params: { id: streamer.id } }">
            <img
              :src="host + streamer.profile_path"
              v-if="streamer.profile_path"
            />
            <img src="../assets/shroud-photo.png" v-else />
          </router-link>
          <div class="streemer-placeholder">
            <h4>{{ streamer.name }}</h4>
            <p>{{ streamer.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "OthersStreamers",
  props: ["streamers", "host", "id", "title"],
  data: () => {
    return {
      videos: []
    };
  },
  created() {
    if (this.$props.id != 0) {
      this.videos = this.$props.streamers.filter(streamer => {
        return streamer.id != this.$props.id;
      });
    } else {
      this.videos = this.$props.streamers;
    }
  }
};
</script>

<style scoped></style>
